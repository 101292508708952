const animations = {
  initial: {
    zIndex: 0,
    opacity: 0,
  },
  visible: (duration) => ({
    zIndex: 2,
    opacity: 1,
    transition: {
      opacity: {
        duration: duration / 1000,
        ease: 'linear',
      },
    },
  }),
  hidden: (duration) => ({
    zIndex: 1,
    opacity: 0,
    transition: {
      opacity: {
        duration: 0,
        delay: duration / 1000,
        ease: 'linear',
      },
    },
  }),
}

export default animations
