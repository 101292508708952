import React from 'react'
import PropTypes from 'prop-types'

// const types = {
//   example: Example,
// }

const SectionRouter = ({ sections, types }) => {
  const output = []
  let prevSection = 'none'

  sections.forEach((section, index) => {
    const sectionType = section.type
    const SectionType = types[sectionType]
    const nextSection = sections[index + 1] ? sections[index + 1].type : 'none'

    if (SectionType) {
      const propsOutput = section
      output.push(
        <SectionType
          key={section.id}
          prev={prevSection}
          next={nextSection}
          {...propsOutput}
        />
      )

      prevSection = sectionType
    }
  })

  return <div>{output}</div>
}

SectionRouter.defaultProps = {
  sections: [],
  types: {},
}

SectionRouter.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.object,
}

export { SectionRouter }
