/**
 * Submit function customised for Basin
 *
 * @param {location} object
 * @param {data} object
 */

import superagent from 'superagent'

const submitToBasin = (url, data) => {
  return new Promise((resolve, reject) => {
    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .send(data)
      .end((err, res) => {
        // https://usebasin.com/docs/features/ajax
        const { status, statusText } = res.xhr
        if (status === 200) {
          resolve()
        } else {
          reject(new Error(statusText))
        }
      })
  })
    .then(() => {
      return true
    })
    .catch((error) => {
      console.warn('Error:', error) // eslint-disable-line no-console
      return false
    })
}

export { submitToBasin }
