import { getRichText } from './getRichText'
// import { getAspectRatio } from './getAspectRatio'
import { getLink } from './getLink'
import { getSlug } from '../../utils/getSlug'
// import { get } from '../../utils/get'
import { getImage } from './getImage'

/**
 * Process prismic slice data that can be passed to components as props
 *
 * @param {array} data
 * @param {Boolean} isDarkMode
 */

export const sectionSerializer = (data, isDarkMode) => {
  const sections = []

  data.forEach((slice, sliceIndex) => {
    if (slice) {
      // section object
      const section = {
        type: slice.slice_type,
        id: slice.id,
      }

      section.id = `section-${sliceIndex}`
      section.isDarkMode = isDarkMode

      // restructure 'primary' object prop to root
      if (slice.primary) {
        Object.keys(slice.primary).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
            slice[key] = slice.primary[key]
          }
        })
        delete slice.primary
      }

      // contact form
      if (slice.slice_type === 'contact_form') {
        section.confirmationText = getRichText(slice, 'confirmation_text.raw')
        section.submitLabel = slice.submit_button_label
        section.url = slice.url
        section.fields = []

        slice.items.forEach((item, index) => {
          let type = getSlug(item.type)
          if (type === 'phone') {
            type = 'tel'
          }

          section.fields.push({
            id: `${sliceIndex}-contact_form-${index}`,
            name: item.name || `contact_form_${index}`,
            placeholder: item.placeholder,
            label: item.placeholder,
            required: item.required || false,
            type,
          })
        })
      }

      // hero
      if (slice.slice_type === 'hero') {
        section.intro = getRichText(slice, 'intro.raw')
      }

      // image
      if (slice.slice_type === 'image') {
        section.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          if (image) {
            section.images.push({
              id: `${sliceIndex}-image-${index}`,
              image,
              captionColumn01: getRichText(item, 'caption_column_01.raw'),
              captionColumn02: getRichText(item, 'caption_column_02.raw'),
            })
          }
        })
      }

      // image_grid
      if (slice.slice_type === 'image_grid') {
        section.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          if (image) {
            section.images.push({
              id: `${sliceIndex}-image_grid-${index}`,
              image,
              caption: getRichText(item, 'caption.raw'),
            })
          }
        })
      }

      // text
      else if (slice.slice_type === 'text') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.description = getRichText(slice, 'description.raw')
        section.descriptionSize = getSlug(slice.description_size)
        section.subColumns = []
        slice.items.forEach((item, index) => {
          section.subColumns.push({
            id: `${sliceIndex}-text-${index}`,
            heading: getRichText(item, 'heading.raw'),
            description: getRichText(item, 'description.raw'),
          })
        })
      }

      // text and image
      else if (slice.slice_type === 'text_and_image') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.description = getRichText(slice, 'description.raw')
        // section.image = getImage(slice, 'image', 'fluid')
        section.captionHeading = getRichText(slice, 'caption_heading.raw')
        section.captionBody = getRichText(slice, 'caption_body.raw')

        section.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          if (image) {
            section.images.push({
              id: `${sliceIndex}-image-${index}`,
              image,
            })
          }
        })
      }

      // logos
      else if (slice.slice_type === 'logos') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.items = []

        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          if (image) {
            section.items.push({
              id: `${sliceIndex}-logo-${index}`,
              image,
              title: item.title,
              link: getLink(item.link),
            })
          }
        })
      }

      // add to output array
      sections.push(section)
    }
  })

  return sections
}
