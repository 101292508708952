import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { BasinForm } from '../../components/BasinForm'
import { RichText } from '../../components/RichText'
import styles from './ContactForm.styles.scss'

const ContactForm = ({ fields, isDarkMode, url, confirmationText }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const handleSuccess = () => setIsSubmitted(true)
  return (
    <Section className={styles.ContactForm} isDarkMode={isDarkMode}>
      <Container>
        {isSubmitted ? (
          <div className={styles.confirmation}>
            <RichText>{confirmationText}</RichText>
          </div>
        ) : (
          <BasinForm fields={fields} url={url} onSuccess={handleSuccess} />
        )}
      </Container>
    </Section>
  )
}

ContactForm.defaultProps = {
  fields: [],
  isDarkMode: false,
  url: undefined,
  confirmationText: undefined,
}

ContactForm.propTypes = {
  fields: PropTypes.array,
  isDarkMode: PropTypes.bool,
  url: PropTypes.node,
  confirmationText: PropTypes.node,
}

export { ContactForm }
