import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Container } from '../../components/Container'
import { RichText } from '../../components/RichText'
import { Section } from '../../components/Section'
import styles from './Text.styles.scss'

const Text = ({
  isDarkMode,
  description,
  descriptionSize,
  heading,
  prev,
  subColumns,
}) => {
  return (
    <Section
      className={styles.Text}
      isDarkMode={isDarkMode}
      paddingTop={prev !== 'none'}
      prev={prev}
    >
      <Container>
        <div className={styles.inner}>
          <div className={styles.head}>
            {heading && (
              <h3 className={styles.heading}>
                <RichText>{heading}</RichText>
              </h3>
            )}
          </div>
          <div className={styles.main}>
            {description && (
              <div
                className={classnames(styles.description, {
                  [styles.mediumText]: descriptionSize === 'default',
                  [styles.smallText]: descriptionSize === 'small',
                })}
              >
                <RichText>{description}</RichText>
              </div>
            )}
            {!!subColumns.length && (
              <div className={styles.subColumns}>
                {subColumns.map((column) => {
                  return (
                    <div key={column.id} className={styles.subColumns__item}>
                      {column.heading && (
                        <h4 className={styles.subColumns__heading}>
                          {column.heading}
                        </h4>
                      )}
                      <div className={styles.subColumns__desc}>
                        <RichText>{column.description}</RichText>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </Container>
    </Section>
  )
}

Text.defaultProps = {
  isDarkMode: false,
  description: undefined,
  descriptionSize: 'default',
  heading: undefined,
  prev: undefined,
  subColumns: [],
}

Text.propTypes = {
  isDarkMode: PropTypes.bool,
  description: PropTypes.node,
  descriptionSize: PropTypes.oneOf(['default', 'small']),
  heading: PropTypes.node,
  prev: PropTypes.string,
  subColumns: PropTypes.arrayOf(PropTypes.object),
}

export { Text }
