import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { get } from '../../utils/get'
import { sectionSerializer } from '../utils/sectionSerializer'
import PageTemplate from '../../templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicPage } = data
  const slices = get(prismicPage, 'data.body') || []
  const isDarkMode = get(prismicPage, 'data.dark_theme') || false
  const sections = sectionSerializer(slices, isDarkMode)

  return <PageTemplate sections={sections} />
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(PagePrismic)

export const query = graphql`
  query PageByID($id: String!) {
    prismicPage(id: { eq: $id }) {
      uid
      id
      type
      data {
        title
        dark_theme
        body {
          ... on PrismicPageBodyContactForm {
            id
            slice_type
            primary {
              confirmation_text {
                raw
              }
              submit_button_label
              url
            }
            items {
              name
              placeholder
              required
              type
            }
          }
          ... on PrismicPageBodyHero {
            id
            slice_type
            primary {
              intro {
                raw
              }
            }
          }
          ... on PrismicPageBodyImage {
            id
            slice_type
            items {
              caption_column_01 {
                raw
              }
              caption_column_02 {
                raw
              }
              image {
                fluid(maxWidth: 2500) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyImageGrid {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              caption {
                raw
              }
            }
          }
          ... on PrismicPageBodyTextAndImage {
            id
            slice_type
            primary {
              heading {
                raw
              }
              description {
                raw
              }
              caption_body {
                raw
              }
              caption_heading {
                raw
              }
            }
            items {
              image {
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              heading {
                raw
              }
              description {
                raw
              }
              description_size
            }
            items {
              heading {
                raw
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPageBodyLogos {
            id
            slice_type
            primary {
              heading {
                raw
              }
            }
            items {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
              image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
