import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '../../components/Container'
import { RichText } from '../../components/RichText'
import { Section } from '../../components/Section'
import { Logo } from '../../components/Logo'
import styles from './Hero.styles.scss'

const Hero = ({ intro, prev }) => {
  const [transitionIn, setTransitionIn] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setTransitionIn(true)
    }, 500)
  }, [])

  return (
    <Section
      className={styles.el}
      paddingTop={false}
      paddingBottom={false}
      isDarkMode
      prev={prev}
    >
      <Container className={styles.container}>
        <div className={styles.inner}>
          {intro && (
            <motion.h1
              className={styles.heading}
              initial={{ opacity: 0 }}
              animate={{
                opacity: transitionIn ? 1 : 0,
                transition: { delay: 2, duration: 0.5 },
              }}
            >
              <RichText>{intro}</RichText>
            </motion.h1>
          )}
        </div>
        <div className={styles.logo}>
          <div className={styles.logo__inner}>
            <Logo isVisible={transitionIn} />
          </div>
        </div>
      </Container>
    </Section>
  )
}

Hero.defaultProps = {
  intro: undefined,
  prev: undefined,
}

Hero.propTypes = {
  intro: PropTypes.node,
  prev: PropTypes.string,
}

export { Hero }
