import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { Img } from '../../components/Img'
import { RichText } from '../../components/RichText'
import { Section } from '../../components/Section'
import styles from './ImageGrid.styles.scss'

const ImageGrid = ({ images, isDarkMode }) => {
  return (
    <Section className={styles.el} isDarkMode={isDarkMode}>
      <Container>
        <div className={styles.inner}>
          {images.map((item) => {
            return (
              <div key={item.id} className={styles.item}>
                <div key={item.id} className={styles.item__container}>
                  <div className={styles.image}>
                    <div className={styles.image__inner}>
                      <Img {...item.image} />
                    </div>
                  </div>
                  <div className={styles.text}>
                    {item.caption && <RichText>{item.caption}</RichText>}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}

ImageGrid.defaultProps = {
  images: [],
  isDarkMode: false,
}

ImageGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      captionColumn01: PropTypes.node,
      captionColumn02: PropTypes.node,
    })
  ),
  isDarkMode: PropTypes.bool,
}

export { ImageGrid }
