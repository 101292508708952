import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { Img } from '../../components/Img'
import { RichText } from '../../components/RichText'
import { Fader } from '../../components/Fader'
import { Section } from '../../components/Section'
import styles from './Image.styles.scss'

const Image = ({ images, isDarkMode }) => {
  return (
    <Section className={styles.el} isDarkMode={isDarkMode}>
      <Container>
        <Fader
          className={styles.fader}
          autoplay
          autoplayDelay={4000}
          controls
          duration={500}
        >
          {images.map((item, index) => {
            return (
              <div key={item.id} className={styles.item}>
                <div className={styles.item__container}>
                  <div className={styles.image}>
                    <div className={styles.image__inner}>
                      <Img {...item.image} objectFit="cover" fillContainer />
                    </div>
                  </div>
                  <div className={styles.caption}>
                    <div className={styles.caption__column}>
                      <span className={styles.counter}>
                        <span className={styles.counter__circle} />
                        <span className={styles.counter__text}>
                          {index + 1}/{images.length}
                        </span>
                      </span>
                    </div>
                    {item.captionColumn01 && (
                      <div className={styles.caption__column}>
                        <RichText>{item.captionColumn01}</RichText>
                      </div>
                    )}
                    {item.captionColumn02 && (
                      <div className={styles.caption__column}>
                        <RichText>{item.captionColumn02}</RichText>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Fader>
      </Container>
    </Section>
  )
}

Image.defaultProps = {
  images: [],
  isDarkMode: false,
}

Image.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      captionColumn01: PropTypes.node,
      captionColumn02: PropTypes.node,
    })
  ),
  isDarkMode: PropTypes.bool,
}

export { Image }
