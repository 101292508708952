import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './TextField.styles.scss'

const TextField = React.forwardRef(
  ({ isRequired, label, id, name, placeholder, type }, ref) => {
    // https://react-hook-form.com/faqs#Howtosharerefusage
    const inputRef = useRef()

    const handleChange = (event) => {
      event.persist()
    }

    return (
      <div className={styles.el}>
        <input
          id={id}
          name={name}
          type={type}
          className={styles.inner__input}
          onChange={handleChange}
          placeholder={placeholder}
          ref={(event) => {
            if (ref) {
              ref(event)
            }
            inputRef.current = event
          }}
        />
        {label && (
          <label className={styles.inner__label} htmlFor={name}>
            {label}
            {isRequired && <span className={styles.required}>*</span>}
          </label>
        )}
      </div>
    )
  }
)

TextField.defaultProps = {
  isRequired: false,
  label: undefined,
  id: undefined,
  placeholder: undefined,
  type: 'text',
}

TextField.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['email', 'tel', 'text']),
}

export { TextField }
