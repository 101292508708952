import React, { useEffect } from 'react'
import classNames from 'classnames'
import LazyLoad from 'vanilla-lazyload'
import PropTypes from 'prop-types'
import objectFitImages from 'object-fit-images'
import styles from './Img.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const Img = ({
  src,
  srcset,
  sizes,
  alt,
  placeholderSrc,
  objectFit,
  objectPosition,
  fillContainer,
  aspectRatio,
  isLazy,
  isResponsive,
  onLoaded,
}) => {
  // configure lazy loading
  const lazyClass = isLazy ? 'lazy' : null

  if (isLazy) {
    // only initialize lazy loading one time for the app
    if (IS_BROWSER && !document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad({
        elements_selector: `.${lazyClass}`,
        callback_loaded: (el) => {
          el.parentElement.setAttribute('data-loaded', 'true')
          if (typeof onLoaded === 'function') {
            onLoaded()
          }
        },
      })
    }
  }

  const objectFitPolyfill = IS_BROWSER && (objectFit || objectPosition)
  if (objectFitPolyfill) {
    objectFitImages()
  }

  // update lazyLoad after first rendering of every image
  useEffect(() => {
    if (IS_BROWSER && isLazy) {
      document.lazyLoadInstance.update()
    }

    if (!isLazy && typeof onLoaded === 'function') {
      if (!isResponsive) {
        const preloadImage = new Image()
        preloadImage.src = src
        preloadImage.onload = () => {
          onLoaded()
        }
      } else {
        console.warn('img: onLoaded event not supported with srcset') // eslint-disable-line no-console
      }
    }
  }, [])

  const containerStyle = {
    paddingTop: aspectRatio && !fillContainer ? `${100 / aspectRatio}%` : null,
  }

  const imgStyle = {
    objectFit,
    objectPosition,
    fontFamily: objectFitPolyfill
      ? `'object-fit: ${objectFit}; object-position: ${objectPosition}'`
      : null,
  }

  return (
    <div
      className={styles.el}
      data-fill-container={fillContainer}
      data-loaded={!isLazy}
      style={containerStyle}
    >
      {isLazy ? (
        <img
          alt={alt || ''}
          className={classNames(styles.img, lazyClass)}
          data-src={src}
          data-srcset={isResponsive ? srcset : null}
          data-sizes={isResponsive ? sizes : null}
          style={imgStyle}
          data-fill-container={aspectRatio ? true : null}
        />
      ) : (
        <img
          alt={alt || ''}
          className={styles.img}
          src={src}
          srcSet={isResponsive ? srcset : null}
          sizes={isResponsive ? sizes : null}
          style={imgStyle}
          data-fill-container={aspectRatio ? true : null}
        />
      )}
      {placeholderSrc && (
        <img src={placeholderSrc} alt="" className={styles.placeholder} />
      )}
    </div>
  )
}

Img.defaultProps = {
  alt: '',
  aspectRatio: null,
  fillContainer: false,
  isLazy: true,
  isResponsive: true,
  objectFit: null,
  objectPosition: null,
  onLoaded: undefined,
  placeholderSrc: null,
  sizes: null,
  src: null,
  srcset: null,
}

Img.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  fillContainer: PropTypes.bool,
  isLazy: PropTypes.bool,
  isResponsive: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  onLoaded: PropTypes.func,
  placeholderSrc: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcset: PropTypes.string,
}

export { Img }
