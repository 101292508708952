import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Section.styles.scss'

const Section = ({
  children,
  className,
  isDarkMode,
  paddingTop,
  paddingBottom,
  prev,
  next,
}) => {
  const classes = classNames(styles.el, className)

  return (
    <section
      className={classes}
      data-section
      data-padding-top={paddingTop}
      data-padding-bottom={paddingBottom}
      data-next={next}
      data-prev={prev}
      data-bg-color={isDarkMode ? 'black' : 'white'}
    >
      <div className={styles.bg} />
      <div className={styles.inner}>{children}</div>
    </section>
  )
}

Section.defaultProps = {
  children: undefined,
  className: undefined,
  isDarkMode: false,
  paddingTop: true,
  paddingBottom: true,
  prev: undefined,
  next: undefined,
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDarkMode: PropTypes.bool,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
}

export { Section }
