import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { Img } from '../../components/Img'
import { Fader } from '../../components/Fader'
import { RichText } from '../../components/RichText'
import { Section } from '../../components/Section'
import styles from './TextAndImage.styles.scss'

const TextAndImage = ({
  captionHeading,
  captionBody,
  description,
  heading,
  images,
  isDarkMode,
  prev,
}) => {
  return (
    <Section className={styles.el} isDarkMode={isDarkMode} prev={prev}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.text}>
            {heading && <h3 className={styles.heading}>{heading}</h3>}
            {description && (
              <div className={styles.description}>
                <RichText>{description}</RichText>
              </div>
            )}
          </div>
          <div className={styles.images}>
            <Fader
              autoplay
              autoplayDelay={4000}
              controls={false}
              duration={500}
            >
              {images.map((item) => {
                return (
                  <div key={item.id} className={styles.item}>
                    <div className={styles.item__container}>
                      <div className={styles.image}>
                        <div className={styles.image__inner}>
                          <Img
                            {...item.image}
                            objectFit="cover"
                            fillContainer
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Fader>
            <div className={styles.caption}>
              {captionHeading && (
                <div className={styles.caption__heading}>
                  <RichText>{captionHeading}</RichText>
                </div>
              )}
              {captionBody && (
                <div className={styles.caption__body}>
                  <RichText>{captionBody}</RichText>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

TextAndImage.defaultProps = {
  captionHeading: undefined,
  captionBody: undefined,
  description: undefined,
  heading: undefined,
  images: [],
  isDarkMode: false,
  prev: undefined,
}

TextAndImage.propTypes = {
  captionHeading: PropTypes.node,
  captionBody: PropTypes.node,
  description: PropTypes.node,
  heading: PropTypes.node,
  images: PropTypes.array,
  isDarkMode: PropTypes.bool,
  prev: PropTypes.string,
}

export { TextAndImage }
