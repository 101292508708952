import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { submitToBasin } from '../../utils/submitToBasin'
import { TextField } from '../TextField'
import styles from './BasinForm.styles.scss'

const BasinForm = ({ id, submitLabel, fields, onSuccess, onError, url }) => {
  const { register, handleSubmit, errors, formState } = useForm()
  const { isSubmitting } = formState

  const onSubmit = async (data) => {
    const payload = data
    const success = await submitToBasin(url, payload) // post via Ajax

    if (success) {
      onSuccess()
    } else {
      onError()
    }
  }

  return (
    <div className={styles.el}>
      {url && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          name={id}
          method="post"
          noValidate
        >
          <div className={styles.inner}>
            <div className={styles.messages}>
              {isSubmitting && (
                <div className={styles.message} data-type="sending">
                  Submitting...
                </div>
              )}
              {Object.keys(errors)
                .reverse()
                .map((inputID) => {
                  const errorMessage = errors[inputID].message
                  if (errorMessage) {
                    return (
                      <div className={styles.message} key={`error-${inputID}`}>
                        ● {errorMessage}
                      </div>
                    )
                  }
                  return undefined
                })}
            </div>
            <div className={styles.fields}>
              {fields.map((item) => {
                let validation
                if (item.type === 'checkbox') {
                  validation = register({
                    required: item.required
                      ? 'Checkbox is required.'
                      : undefined,
                  })
                } else {
                  validation = register({
                    required: item.required
                      ? `${item.label} is required.`
                      : undefined,
                    pattern:
                      item.type === 'email'
                        ? {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Email address is invalid.',
                          }
                        : undefined,
                  })
                }
                if (
                  item.type === 'text' ||
                  item.type === 'email' ||
                  item.type === 'tel'
                ) {
                  return (
                    <div className={styles.field} key={item.id}>
                      <TextField
                        type={item.type}
                        name={item.name}
                        id={item.id}
                        ref={validation}
                        placeholder={item.placeholder}
                      />
                    </div>
                  )
                }
                if (item.type === 'textarea') {
                  return (
                    <div className={styles.textarea} key={item.id}>
                      <label
                        htmlFor={item.name}
                        className={styles.textarea__label}
                      >
                        {item.label}
                      </label>
                      <textarea
                        name={item.name}
                        id={item.id}
                        ref={validation}
                        className={styles.textarea__input}
                        rows="5"
                      />
                    </div>
                  )
                }
                return undefined
              })}
            </div>
            <div className={styles.footer}>
              <button type="submit" className={styles.button}>
                {submitLabel}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

BasinForm.defaultProps = {
  id: 'contact-form',
  submitLabel: 'Submit',
  fields: [],
  onSuccess: () => {},
  onError: () => {},
  url: undefined,
}

BasinForm.propTypes = {
  id: PropTypes.string,
  submitLabel: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    })
  ),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  url: PropTypes.string,
}

export { BasinForm }
