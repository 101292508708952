import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { Img } from '../../components/Img'
import { Link } from '../../components/Link'
import styles from './Logos.styles.scss'

const Logos = ({ isDarkMode, heading, items }) => {
  return (
    <Section className={styles.el} isDarkMode={isDarkMode}>
      <Container>
        <div className={styles.inner}>
          {heading && <h3 className={styles.heading}>{heading}</h3>}
          <div className={styles.logos}>
            {items.map((item) => {
              return (
                <div key={item.id} className={styles.logos__item}>
                  <Link
                    to={item.link?.url}
                    target={item.link?.target}
                    aria-label={item.title}
                  >
                    <div className={styles.logo__image}>
                      <Img
                        {...item.image}
                        fillContainer
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Section>
  )
}

Logos.defaultProps = {
  isDarkMode: false,
  heading: undefined,
  items: [],
}

Logos.propTypes = {
  isDarkMode: PropTypes.bool,
  heading: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
}

export { Logos }
