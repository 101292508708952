import React from 'react'
import PropTypes from 'prop-types'
import { SectionRouter } from '../../components/SectionRouter'
import { ContactForm } from '../../sections/ContactForm'
import { Hero } from '../../sections/Hero'
import { Logos } from '../../sections/Logos'
import { Image } from '../../sections/Image'
import { ImageGrid } from '../../sections/ImageGrid'
import { Text } from '../../sections/Text'
import { TextAndImage } from '../../sections/TextAndImage'
import styles from './Page.styles.scss'

const Page = ({ sections }) => {
  return (
    <div className={styles.el}>
      <SectionRouter
        sections={sections}
        types={{
          contact_form: ContactForm,
          hero: Hero,
          logos: Logos,
          image: Image,
          image_grid: ImageGrid,
          text: Text,
          text_and_image: TextAndImage,
        }}
      />
    </div>
  )
}

Page.defaultProps = {
  sections: [],
}

Page.propTypes = {
  sections: PropTypes.array,
}

export default Page
